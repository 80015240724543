import React, { Fragment } from 'react';
import Button from 'reusecore/src/elements/Button';
import { Icon } from 'react-icons-kit';
import { socialTwitter } from 'react-icons-kit/ionicons/socialTwitter';
import { facebook2 } from 'react-icons-kit/icomoon/facebook2';
import { linkedin } from 'react-icons-kit/ikons/linkedin';
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton
} from 'react-share';

function getSize(size) {
  switch (size) {
    case 'sm':
      return ['30px', '30px'];
    case 'md':
      return ['50px', '50px'];
    case 'lg':
      return ['80px', '80px'];
    default:
      return ['48px', '48px'];
  }
}
const ShareButtonGroup = ({
  node,
  prefix,
  size = 'md',
  color,
  transparent
}) => {
  // const sizeCorrected = size === 'lg' ? sizeLG : size === 'md' ? sizeMD : sizeSM;
  const iconSize = getSize(size);
  const outlineBtnStyle = {
    minWidth: iconSize,
    fontSize: '16px',
    fontWeight: '700',
    color: color || '#fff',
    p: '5px 10px'
  };

  const {
    excerpt,
    frontmatter: { title, slug }
  } = node;

  const url = `https://www.upsolar.cl/${prefix}/${slug}`;

  return (
    <Fragment>
      <TwitterShareButton url={url} title={title}>
        <Button
          // title="Share on Twitter"
          variant={transparent && 'textButton'}
          iconPosition="left"
          icon={<Icon icon={socialTwitter} />}
          {...outlineBtnStyle}
          className="btnWithoutColor"
        />
      </TwitterShareButton>

      <FacebookShareButton url={url} quote={excerpt}>
        <Button
          // title="Share on Facebook"
          variant={transparent && 'textButton'}
          iconPosition="left"
          icon={<Icon icon={facebook2} />}
          {...outlineBtnStyle}
          className="btnWithoutColor"
        />
      </FacebookShareButton>

      <LinkedinShareButton url={url} title={title} description={excerpt}>
        <Button
          // title="Share on Facebook"
          variant={transparent && 'textButton'}
          iconPosition="left"
          icon={<Icon icon={linkedin} />}
          {...outlineBtnStyle}
          className="btnWithoutColor"
        />
      </LinkedinShareButton>
    </Fragment>
  );
};

export default ShareButtonGroup;
